<template>
  <div class="auth-wrapper auth-v1 px-2 ">
    <div class="auth-inner py-2">
      <b-overlay
        :show="isLoading"
        spinner-variant="primary"
      >
        <b-card class="mb-0 text-center">
          <b-link class="brand-logo">
            <logo />
            <h2 class="brand-text text-primary ml-1">
              {{ appName }}
            </h2>
          </b-link>

          <b-card-title class="mb-1">
            {{ $t('Welcome to') }} {{ appName }}!
          </b-card-title>
          <b-card-text v-if="!isLoading">
            {{ $t('invitationMessage', { slot: `${inviterFirstName} ${inviterLastName}` }) }}
          </b-card-text>

          <div class="mt-2">
            <b-button
              block
              variant="primary"
              :disabled="isAccepting || isRejecting"
              @click="acceptInvitation"
            >
              <span
                v-if="isAccepting"
                class="d-flex justify-content-center"
              >
                <b-spinner small />
              </span>
              <template v-else>
                {{ $t('Accept') }}
              </template>
            </b-button>
            <b-button
              block
              variant="outline-primary"
              :disabled="isAccepting || isRejecting"
              @click="rejectInvitation"
            >
              <span
                v-if="isRejecting"
                class="d-flex justify-content-center"
              >
                <b-spinner small />
              </span>
              <template v-else>
                {{ $t('Ignore') }}
              </template>
            </b-button>
          </div>
          <div class="mt-2">
            <b-link
              href="https://www.sporkinc.com/"
              target="_blank"
            >
              {{ $t('More about') }} {{ appName }}
            </b-link>
          </div>
        </b-card>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BSpinner,
  BOverlay,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import { $themeConfig } from '@themeConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import GET_ORGANIZATION_USER_BY_INVITATION_CODE from '@/gql/query/getOrganizationUserByInvitationCode.gql'
import ACCEPT_INVITATION_TO_ORGANIZATION from '@/gql/mutation/invitation/acceptInvitationToOrganization.gql'
import REJECT_INVITATION_TO_ORGANIZATION from '@/gql/mutation/invitation/rejectInvitationToOrganization.gql'

export default {
  name: 'Invitation',
  components: {
    BButton,
    BCard,
    BCardTitle,
    BLink,
    Logo,
    BCardText,
    BSpinner,
    BOverlay,
  },
  data() {
    return {
      appName: $themeConfig.app.appName,
      isLoading: true,
      isAccepting: false,
      isRejecting: false,
      inviterFirstName: '',
      inviterLastName: '',
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      if (!this.$route.query.invitationCode) {
        this.$router.push({ name: 'error' })
        return
      }

      try {
        const response = await this.$apollo.query({
          query: GET_ORGANIZATION_USER_BY_INVITATION_CODE,
          variables: {
            invitationCode: this.$route.query.invitationCode,
          },
        })

        this.inviterFirstName = response.data.getOrganizationUserByInvitationCode.inviterFirstName
        this.inviterLastName = response.data.getOrganizationUserByInvitationCode.inviterLastName
      } catch (error) {
        this.$router.push({ name: 'error' })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async acceptInvitation() {
      this.isAccepting = true

      try {
        await this.$apollo.mutate({
          mutation: ACCEPT_INVITATION_TO_ORGANIZATION,
          variables: {
            invitationCode: this.$route.query.invitationCode,
          },
        })

        this.$router.push({ name: 'dashboard' })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isAccepting = false
      }
    },
    async rejectInvitation() {
      this.isRejecting = true

      try {
        await this.$apollo.mutate({
          mutation: REJECT_INVITATION_TO_ORGANIZATION,
          variables: {
            invitationCode: this.$route.query.invitationCode,
          },
        })

        this.$router.push({ name: 'dashboard' })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: Array.isArray(error.graphQLErrors[0].message)
              ? error.graphQLErrors[0].message[0]
              : error.graphQLErrors[0].message,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isRejecting = false
      }
    },
  },
}
</script>

<style lang="sass">
  @import '@core/scss/vue/pages/page-auth.scss'
</style>
